import React, { useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import _get from 'lodash/get';

// bonnet
import { useDevice } from '@bonnet/next/device';

import { formatCurrency } from '@atc/string-fns';

import {
    Box,
    Button,
    Glyphicon,
    ResponsiveImage,
    ScrollPanel,
    SideBarToModal,
    SuperTable,
    Text,
} from 'reaxl';
import { sendClick } from 'reaxl-analytics';
import { customOrderYours, snapshotPaymentDetailsClick } from 'reaxl-analytics-handlers';
import {
    useFeatures,
} from 'reaxl-features';
import {
    InventoryImagePlaceholder,
} from 'reaxl-molecules';
import {
    WalletPaymentDetails,
} from 'reaxl-wallet';

import addSpecAttribute from '@/utilities/compareTableBuilder';

import {
    currentPageNameDuck,
    kbbVRSDataDuck,
    paymentsDuck,
    preorderDuck,
} from '@/ducks';

import { bapActiveInteractionDuck } from '@/ducks/bap';
import {
    srpActiveInteractionDuck,
    srpFiltersDuck,
} from '@/ducks/srp';

import PaymentCalculationFetcher from '@/fetchers/PaymentCalculationFetcher';

import useBapNavigation from '@/hooks/useBapNavigation';

// fetchers

import CompareTrimFuelTypeDropdown from './srp/CompareTrimFuelTypeDropdown';
import FeedbackContainer from './srp/FeedbackContainer';

// functions
const checkmarkGlyph = (
    <Glyphicon
        className="text-success"
        glyph="success"
    />
);

const unavailableGlyph = (
    <Glyphicon
        className="text-gray-muted"
        glyph="close"
    />
);

const legend = (

    <Box
        backgroundColor="neutral-secondary"
        borderColor="neutral-tertiary"
        className="display-flex flex-wrap justify-content-between text-size-200 rounded"
        padding="compact"
    >
        <div>
            {checkmarkGlyph}
            {' Standard '}
        </div>
        <div>
            {unavailableGlyph}
            {' Unavailable'}
        </div>
    </Box>

);

const renderFuelTypeDropdown = () => <CompareTrimFuelTypeDropdown />;

const renderCategoryTitle = (title) => (
    <Text weight="bold">
        {title}
    </Text>
);

/*
 * SideBarToModal of preorder vrs data comparing each active trim
 */
function CompareTrimsContainer({ modal = false }) {
    const dispatch = useDispatch();
    const filtersValues = useSelector(srpFiltersDuck.selectors.getFiltersValues);
    const userZip = _get(filtersValues, 'location.zip', '') || _get(filtersValues, 'zip', '');
    const pageName = useSelector(currentPageNameDuck.selectors.getDuckState);
    const bap = pageName === 'bap';
    const trims = bap ? kbbVRSDataDuck.selectors.getSelectedMakeModelTrims : kbbVRSDataDuck.selectors.getActiveModelTrims;
    const activeTrims = useSelector(trims);
    const fuelTypeFilter = useSelector(srpActiveInteractionDuck.selectors.getCompareTrimsFuelType);
    const showModal = bap ? bapActiveInteractionDuck.selectors.getShowTrimsModal : srpActiveInteractionDuck.selectors.getShowTrimsModal;
    const isModalActive = useSelector(showModal);
    const isTrimsTabOpen = useSelector(srpActiveInteractionDuck.selectors.getIsTrimsTabOpen);
    const activeSpecs = useSelector(kbbVRSDataDuck.selectors.getSpecs);

    const clearPreorderTrims = () => dispatch(preorderDuck.creators.clearPreorderTrims());
    const updateListingPayments = () => dispatch(paymentsDuck.creators.updateListingPayments());
    const updateMyWallet = (options) => dispatch(paymentsDuck.creators.updateMyWallet(options));

    const { atcMake, atcModel } = activeTrims?.[0] || {};
    const device = useDevice();
    const isXs = _get(device, 'is.xs', false);
    const navigateToBap = useBapNavigation();

    const {
        nds: ndsFeature,
        preorder: [enablePreorder],
    } = useFeatures([
        'nds',
        'preorder',
    ]);

    // handle hiding the compare trims side bar
    // setting activeModel to 'none' prevents extra vrs calls from occurring on hide - see CompareTrimsLink.jsx

    const updateActiveInteraction = useCallback(() => {
        const options = { showTrimsModal: false };
        if (!isTrimsTabOpen) { options.activeModel = 'none'; }
        if (bap) {
            dispatch(bapActiveInteractionDuck.creators.setKeys(options));
        } else {
            dispatch(srpActiveInteractionDuck.creators.setKeys(options));
        }

    }, [bap, dispatch, isTrimsTabOpen]);

    const handleOnHide = () => {
        updateActiveInteraction();
        clearPreorderTrims();
    };

    const handleWalletClick = (event, preorderId) => {
        const extra = {
            inventoryId: preorderId,
            par: 'comp_trim',
            clickType: 'compTrim',
        };
        sendClick(snapshotPaymentDetailsClick, event, extra);
        updateMyWallet({
            listingId: preorderId,
            showPaymentDetailsModal: true,
        });
    };

    const handleCustomOrderClick = useCallback((event) => {
        navigateToBap({
            makeCode: activeTrims?.[0]?.atcMakeCode,
            modelCode: activeTrims?.[0]?.atcModelCode,
            zip: userZip,
            useReferrer: true,
        });

        updateActiveInteraction();

        sendClick(customOrderYours, event, {
            makeId: activeTrims?.[0]?.atcMakeCode,
            makeName: activeTrims?.[0]?.atcModelCode,
        });
    }, [navigateToBap, activeTrims, userZip, updateActiveInteraction]);

    // TODO listingId should be preorderTrim + index
    const renderMonthlyPayment = (atcModelCode, index) => {
        const preorderId = `preorderTrim-${atcModelCode}-${index}`;
        return (
            <WalletPaymentDetails
                isCompare
                isPreorder
                listingId={preorderId}
                onMyWalletClick={(event) => handleWalletClick(event, preorderId)}
                personalPaymentProps={{
                    fetcher: PaymentCalculationFetcher,
                    paymentsEnabled: true,
                    ndsFeature,
                }}
                updateListingPayments={updateListingPayments}
                updateOnPaymentChange
                userZip={userZip}
            />
        );
    };

    const renderTable = () => {
        const headerData = [renderFuelTypeDropdown()];
        const msrpData = [renderCategoryTitle('Starting MSRP')];
        const monthlyPaymentData = [renderCategoryTitle('Est. Monthly Payment')];
        const rowCategories = {
            heatedSeatsData: [renderCategoryTitle('Heated Seats')],
            leatherSeatsData: [renderCategoryTitle('Leather Seats')],
            rearviewCameraData: [renderCategoryTitle('Rearview Camera')],
            bluetoothData: [renderCategoryTitle('Bluetooth')],
            alarmSystemData: [renderCategoryTitle('Alarm System')],
            laneDepartureData: [renderCategoryTitle('Lane Departure')],
            navigationData: [renderCategoryTitle('Navigation')],
            remoteStartData: [renderCategoryTitle('Remote Start')],
            sunroofData: [renderCategoryTitle('Sunroof')],
        };

        const filteredActiveTrims = activeTrims
            .filter((trim) => !fuelTypeFilter || trim.engineType === fuelTypeFilter);

        filteredActiveTrims.forEach((item) => {
            item.specs = activeSpecs[`${item.vehicleId}`];
        });

        filteredActiveTrims.forEach((trim, index) => {
            const trimName = trim?.trimName > 30 ? `${trim?.trimName.slice(0, 30)}...` : trim?.trimName;
            const specInformation = addSpecAttribute(trim.specs);

            const trimTitle = (
                <div
                    className="margin-auto"
                    style={{
                        width: '100px',
                    }}
                >
                    {trim?.imagePath
                        ? <ResponsiveImage src={trim.imagePath} />
                        : <InventoryImagePlaceholder height={40} />}
                    <Text
                        className="padding-vertical-2"
                        componentClass="div"
                        color="subdued"
                        size={200}
                    >
                        {trimName}
                    </Text>
                </div>
            );
            headerData.push(trimTitle);
            msrpData.push(formatCurrency(trim?.nationalMSRP));
            monthlyPaymentData.push(renderMonthlyPayment(trim?.atcModelCode, index));
            Object.keys(rowCategories).forEach((key) => rowCategories[key].push(specInformation[`${key}`]));
        });

        const bodyData = [
            msrpData,
            monthlyPaymentData,
            ...Object.values(rowCategories),
        ];

        const superTable = (
            <SuperTable
                cellIndexWidth={{ index: 0, value: 125 }}
                data-cmp="tbl-comp-trm"
                tableHeadings={headerData}
                tableRows={bodyData}
                navMarginTop={modal ? 40 : 20}
                navMarginLeft={185}
                inModal={modal}
                className="super-table--thead-align-top"
            />
        );

        const renderSuperTable = () => {
            const contentWithFooter = (
                <>
                    {superTable}
                    <div className="row margin-lg-horizontal-3 margin-bottom-4 text-center">
                        <div className="col-xs-12 margin-bottom-3 margin-top-4">
                            <div style={{ display: 'inline-block', minWidth: '200px' }}>
                                {legend}
                            </div>
                        </div>
                        <div className="col-xs-12">
                            <FeedbackContainer
                                data="information-helpful::trim::"
                            />
                        </div>
                    </div>
                </>
            );
            return isXs && modal ? contentWithFooter : superTable;
        };
        return renderSuperTable();
    };

    const customOrderYoursCTA = (
        <div className="pull-right">
            <Button
                bsStyle="primary"
                bsSize="small"
                block
                onClick={handleCustomOrderClick}
            >
                Custom Order Yours
            </Button>
        </div>
    );

    const renderCompareTrimsFooter = () => (
        <div className="row">
            <div className="col-sm-5 col-sm-offset-4 col-lg-offset-0 col-md-3 text-lg-left padding-md-3">{legend}</div>
            <div className="col-sm-7 col-md-7 text-sm-left text-lg-center padding-sm-3">
                <FeedbackContainer
                    data="information-helpful::trim::"
                />
            </div>
            {enablePreorder && !bap && (
                <div className="col-sm-5 col-md-2 padding-sm-3 text-right">
                    {customOrderYoursCTA}
                </div>
            )}
        </div>
    );

    const renderCompareTrimsModal = () => (
        <SideBarToModal
            collapsed={!!isXs}
            content={renderTable()}
            modalOptions={{
                bsSize: 'large',
                scrollPanel: true,
            }}
            onHide={handleOnHide}
            sideBarOptions={{
                fullWidth: true,
                action: customOrderYoursCTA,
            }}
            show={isModalActive}
            title={`Compare ${atcMake} ${atcModel} Trims`}
            footerContent={renderCompareTrimsFooter()}
        />
    );

    const renderCompareTrimsContainer = () => (
        <>
            <ScrollPanel className="margin-top-4">
                {renderTable()}
            </ScrollPanel>
            {renderCompareTrimsFooter()}
        </>
    );

    return (
        modal ? renderCompareTrimsModal() : renderCompareTrimsContainer()
    );
}

export default CompareTrimsContainer;
